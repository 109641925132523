import { BrowserRouter, Navigate, Routes, Route } from "react-router-dom";
import MainLayout from "../layouts/MainLayout";
import Home from "../pages/Home";
import NotFound from "../pages/NotFound";
import AboutUs from "../pages/AboutUs";
import ContactUs from "../pages/ContactUs";
import ServiceNow from "../pages/services/serviceNow";
import AppDevelopment from "../pages/services/appDevelopment";
import Government from "../pages/industries/government";
import DataAi from "../pages/services/dataAi";
import Cloud from "../pages/services/cloud";
import Consulting from "../pages/services/consulting";
import EnterpriseIntegration from "../pages/services/enterpriseIntegration";
import ModernizeTransform from "../pages/services/modernizeTransform";
import MicrosoftDynamics from "../pages/services/microsoftDynamics";
import Seven83Blog from "../pages/seven83_blog/seven83_blog";
import Healthcare from "../pages/industries/healthcare";
import Logistics from "../pages/industries/logistics";
import Manufacturing from "../pages/industries/manufacturing";
import Telecommunications from "../pages/industries/telecommunications";
import BankingFinancialInsurance from "../pages/industries/bankingFinancialInsurance";
import BlogDetails from "../pages/seven83_blog/[id]";
function App() {
  return (
    <BrowserRouter>
      <Routes>
        <Route path="/" element={<MainLayout />}>
          <Route path="" element={<Home />} />
          <Route path="aboutUs" element={<AboutUs />} />
          <Route path="seven83_blog" element={<Seven83Blog />} />
          <Route path="seven83_blog/:id" element={<BlogDetails />} />
          <Route path="serviceNow" element={<ServiceNow />} />
          <Route path="appDevelopment" element={<AppDevelopment />} />
          <Route path="dataAi" element={<DataAi />} />
          <Route path="cloud" element={<Cloud />} />
          <Route path="consulting" element={<Consulting />} />
          <Route
            path="enterpriseIntegration"
            element={<EnterpriseIntegration />}
          />
          <Route path="modernizeTransform" element={<ModernizeTransform />} />
          <Route path="microsoftDynamics" element={<MicrosoftDynamics />} />
          <Route path="government" element={<Government />} />
          <Route path="healthcare" element={<Healthcare />} />
          <Route path="manufacturing" element={<Manufacturing />} />
          <Route path="telecommunications" element={<Telecommunications />} />
          <Route
            path="bankingFinancialInsurance"
            element={<BankingFinancialInsurance />}
          />
          <Route path="logistics" element={<Logistics />} />
          <Route path="contactUs" element={<ContactUs />} />
          <Route path="not-found" element={<NotFound />} />
          <Route path="*" element={<Navigate to="/not-found" />} />
        </Route>
      </Routes>
    </BrowserRouter>
  );
}

export default App;
